<template>
  <div>
    <div class="header-buttons">
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        :required-permissions="requiredPermissions.editButton"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        :required-permissions="requiredPermissions.saveButton"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="group.groupId && !group.hasExternalGroupMapping"
        :record-id="group.groupId"
        :required-permissions="requiredPermissions.deleteButton"
        action-id-param="groupId"
        store-name="group"
        list-router-path="permissionGroup"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/permissionGroup')"
      />
    </div>
    <Input
      v-model="group.name"
      @blur="$v.group.name.$touch()"
      :error="$v.group.name.$error"
      id="group_name"
      :label="$t('permissionGroup.name')"
      :disabled="disabled"
    />
    <PermissionGroupTable
      v-if="groupLoaded"
      ref="permissionTable"
      :selected-capabilities="group.capabilities"
      :disabled="disabled"
    />
  </div>
</template>

<script>

import PermissionGroupModel from '@/model/PermissionGroupModel'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import PermissionGroupTable from '@/components/permissionGroup/PermissionGroupTable'
import Input from '@/components/form/inputs/Input'
import NotifyService from '@/services/NotifyService'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'PermissionGroupNewView',
  data () {
    return {
      groupLoaded: true, // set to true as there is no group to be loaded for 'NewView'
      group: this._.cloneDeep(PermissionGroupModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_PERMISSION_GROUP_PERMISSIONS
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    group: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      }
    }
  },
  components: {
    ModuleFormDeleteButton,
    ModuleFormButton,
    Input,
    PermissionGroupTable
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      const action = this.group.groupId ? 'update' : 'create'
      const successMessage = this.group.groupId ? 'record_was_updated' : 'record_was_created'
      this.group.capabilities = this.$refs.permissionTable.getCapabilitiesForSave()
      this.$store.dispatch(`group/${action}`, this.group)
        .then((groupId) => {
          if (this.$store.getters['group/error'] === null) {
            NotifyService.setSuccessMessage(this.$t(`notify.${successMessage}`))
            if (!this.group.groupId) {
              this.group.groupId = groupId
            }
            this.goToEdit()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['group/error'])
          }
        })
        .catch(error => console.log(error))
    },
    goToEdit () {
      this.$router.push('/permissionGroup/' + this.group.groupId + '/edit')
    }
  }
}
</script>

<style lang="scss" scoped>
.header-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}
</style>
