<script>
import BeUserPermissionTable from '@/components/beUser/BeUserPermissionTable'

export default {
  name: 'PermissionGroupTable',
  extends: BeUserPermissionTable,
  data () {
    return {
      headerText: this.$t('permissionGroup.capabilities')
    }
  }
}
</script>
